@import "/src/styles/mixins";

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

$background: #313143;

$maxWidth: 250px;

.googleBtn {
  width: 280px;
  height: 57px;
  background: linear-gradient(180deg, #6441ed 10%, #875ccb 73.41%);
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
  margin: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .googleIconWrapper {
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 7px;
    //background-color: $white;
    //height: 50px;
  }

  .googleIcon {
    width: 24px;
    height: 24px;
  }

  .btnText {
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }

  &:hover {
    background-color: #45455f;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      display: flex;
      align-items: center;
      margin: auto;
  
      width: 24px;
      height: 24px;
      background-image: url("../../../../../assets/wallet-arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &:active {
    background: $button-active-blue;
  }
}


.fbBtn {
  width: 280px;
  height: 57px;
  //background-color: #4051b5;
  background-color: var(--wallet-button);
  border-radius: 8px;
  //border: 2px solid #4051b5;
  cursor: pointer;
  color: white;

  .googleIconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 7px;
    //background-color: $white;
    width: 60px;
    //height: 50px;
  }

  .text {
    display: flex;
    flex-direction: row;
    width: 100%;
    //justify-content: center;
    align-items: center;
  }

  .googleIcon {
    width: 24px;
    height: 24px;
  }

  .btnText {
    margin: 15px 1px 0 40px;
    color: white;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    background-color: #45455f;
    .text {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        display: flex;
        align-items: center;
        margin: auto;
    
        width: 24px;
        height: 24px;
        background-image: url("../../../../../assets/wallet-arrow.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &:active {
    background: $button-active-blue;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);