@import "variables";

@mixin desktop {
  @media (min-width: ($breakpoint)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1263px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin small_screen {
  @media (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin link($initial: fade-out(white, 0.5), $hover: white) {
  @include transition(color);
  color: $initial;

  &:hover,
  &.active {
    color: $hover;
  }
}

@mixin button {
  text-transform: uppercase;
}

@mixin marketplaceButton($height: 64px,$width: 180px, $padding: 0px) {
  background: linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
  border-radius: 10px;
  color: white;
  margin-right: 15px;
  font-weight: 500;
  width: $width;
  height: $height;
  line-height: 40px;
  transition: all 0.2s ease;
  padding: $padding;
  border: transparent !important;

  &:hover {
    background: linear-gradient(95.63deg, #3168f7 0%, #3168f7 106.47%);
    text-decoration: none;
    color: white;
  }

  &:focus {
    transform: scale(0.9);
    text-decoration: none;
  }
}

@mixin marketplaceButtonOutline($height: 180px, $width: 64px) {
  border: solid 3px transparent !important;
  background-image: linear-gradient(#191c2e, #191c2e), linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #191c2e inset;
  border-radius: 10px;
  color: #01CDFD;
  margin-right: 15px;
  font-weight: 500;
  width: 180px;
  padding: 30px;
  line-height: 40px;
  transition: all 0.2s ease;

  &:hover {
    background-image: linear-gradient(#191c2e, #191c2e), linear-gradient(95.63deg, #3168f7 0%, #01cdfd 106.47%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #191c2e inset;
    color: white;
  }

  &:focus {
    transform: scale(0.9);
  }
}

@mixin transition($property: all) {
  transition: $property $transition-duration;
}

/* app */
@mixin app {
  font-size: $font-size;

  position: relative;
  min-height: 100vh;
  padding-bottom: $footer-height;

  @include mobile {
    padding-bottom: $footer-height-mobile;
  }
}

@mixin purple_btn {
  background-color: $theme_light_purple_btn !important;
  border: none !important;
  &:hover {
    background-color: $theme_dark_purple_btn !important;
    border: none !important;
  }
}
