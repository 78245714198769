@import "../../../../styles/mixins";

.courses {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  gap: 20px;
  margin: 10px 0;

  .card {
    flex-grow: 1;
    flex-basis: 300px;
    max-width: 50%;
    margin: 10px 0;

    padding: 20px;
    background-color: $secondarybg;
    border-radius: $border-radius;
    border: solid 1px $border-color;

    @include mobile {
      max-width: 100%;
    }

    .courseImage {
      overflow: hidden;
      width: 100%;
      height: 350px;
      border-radius: $border-radius;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include mobile {
        height: 250px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      padding: 10px 0;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      color: rgba(246, 245, 255, 0.6);
    }

    &:hover {
      background-color: $hoverbg;
      border: $border;
      cursor: pointer;
    }
  }
}