@import "../../../styles/mixins.scss";
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');


.discover {
  width: 100%;
  height: max-content;
  //max-height: 800px;
  //height: 970px;
  padding: 0 20px;
  margin-top: 40px;
  //margin-bottom: 200px;

  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }

  .collection {
    display: flex;
    //justify-content: center;
    flex-wrap: wrap;
    text-align: left;
    gap: 20px;

    @include mobile {
      margin-top: 50px;
    }
  }

  &:hover {
    box-shadow: none !important;
    border: none !important;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }


  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 0;
      font-size: 38px;
      line-height: 24px;
    }
  }

  .subHeading {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.7;

    @include mobile {
      padding: 10px 0;
    }
  }

  .content {
    padding: 0 !important;
  }

  .item {
    flex-grow: 1;
    //flex-basis: 300px;
    width: 440px;
    max-width: calc(33% - 20px);
    margin: 10px 0;
    background-color: $secondarybg;
    border-radius: $border-radius;
    border: solid 1px $border-color;

    padding: 0 !important;
    
    position: relative;
    background: transparent;
    overflow: hidden !important;
    transform: translateY(0px) !important;
    transition: ease-in-out 200ms;

    &:hover {
      border: solid 1px $blue;
    }

    @include tablet {
      flex-basis: 100%;
      max-width: calc(50% - 20px);
    }

    @include mobile {
      flex-basis: 100%;
      max-width: calc(50% - 10px);
    }

    img {
      height: 100% !important;
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
      object-fit: cover !important;
      transition: ease-in-out 400ms;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(358deg, #000, transparent);
      transition: ease-in-out 400ms;
    }
    
    .type {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      top: 20px;
      right: 10px;

      font-weight: 500;
      font-size: 18px;
      color: #fff;
      
      border-radius: 50px;
      padding: 0px 15px;
      //border: 1px solid #3B395A;
      //background: #151331;
      background: -webkit-linear-gradient(358deg,$primary,$blue);


      span {
        color: var(--blue);
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
    }

    .type_upcoming {
      background: #03011ecc;
      color: #fff;
    }

    @include mobile {
      width: 100%;
    }

    .club_details {
      position: absolute;
      bottom: 0px;
      left: 0;
      border-radius: 24px !important;
      overflow: hidden !important;
      transition: ease-in-out 400ms;

      @include mobile {
        top: 65%;
        .details {
          opacity: 1 !important;
          font-size: 14px !important;
        }
        .content {
          opacity: 1 !important;
        }
      }

      .content {
        opacity: 0;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        color: #999CB6 !important;
        transition: ease-in-out 400ms;
      }

      .details {
        //opacity: 0;
        padding: 20px;
        transition: ease-in-out 400ms;
      }

      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;

        padding: 0 10px;
        margin-top: 20px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    span {
      width: 100%;
      height: 100%;
    }
  }
}