@import "/src/styles/mixins";

.ChatWidget {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    text-align: left;

    button {
        background: transparent;
        border: none;
        position: absolute;
        right: 20px;
        bottom: 20px;
        &:hover {
            cursor: pointer;
        }
    }

    .chatCard {
        width: 400px;
        height: 220px;
        bottom: 0;
        right: 40px;

        border-radius: 15px;
        padding: 40px;

        background-color: #fff;
        box-shadow: 8px 8px 0px -4px #8877ea, 8px 8px 18px 0px #8877ea;
        position: absolute;

        @include mobile {
            width: 350px !important;
            right: 0px !important;
        }

        p {
            color: #000;
            margin: 20px 0;
        }

        .btn {
            display: flex;
            align-items: center;
            gap: 10px;
            position: absolute;
            margin: auto;
            bottom: 50px;
            left: 0;
            right: 0;
            padding: 0 40px;

            background: #242425;
            width: calc(100% - 80px);
            height: 55px;
            border-radius: 12px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #fff;

            @include mobile {
                font-size: 14px;
            }

            img {
                width: 30px;
                @include mobile {
                    width: 20px;
                }
            }
        }
    }
}