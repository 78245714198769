@import '../../../styles/mixins';

.wrapper {
    //background-image: url('/assets/confetti.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 50%;
    //margin-top: 185px;
    padding: 0 15px;
    text-align: center;
    margin: 0 auto;
    .card {
        width: 600px !important;
        height: 300px;
        margin: 50px auto;
        padding: 50px 0;
        background: $secondarybg;
        border-radius: $border-radius;

        @include mobile {
            width: 90vw !important;
            padding: 50px 20px;
        }
        img {
            max-width: 200px;
            margin: 20px 0;
        }
        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            text-align: center;
            letter-spacing: -0.03em;

            color: #FFFFFF;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            text-align: center;

            color: #FFFFFF;

            opacity: 0.7;

            padding: 10px 0 30px;
        }

        .try_again {
            //background: linear-gradient(96.68deg, #3e4a4b 0%, #3e4a4b 105.7%), #F6F5FF;
            color: #fff !important;
            background-color: transparent !important;
            border-color: $secondary !important;
            &:hover {
                background-color: $secondary !important;
            }
        }
    }
}