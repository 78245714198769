@import "../../../styles/mixins";

.post {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  .post_artist {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .post_detail {
    max-width: calc(100% - 70px);

    .content {
      padding: 10px 0;
      .content_media {
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;

          border: $border;
          border-radius: 10px;
          margin: 20px 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          
          border: $border;
          border-radius: 10px;
          margin: 20px 0;
        }
      }
    }
    /* p {
      font-weight: 500;
    } */

    .actions {
      display: flex;
      gap: 20px;

      font-size: 14px;
      color: $darkgray;

      cursor: pointer;
      .action {
        &:hover {
          color: $primary;
        }
      }
    }

    .date {
      font-size: 14px;
      color: $darkgray;
    }
  }
}

.comments {
  min-height: 400px;
  //max-height: 700px;
  max-height: 100%;
  margin-left: auto;
  padding-bottom: 40px;
  overflow: scroll;
  position: relative;

  @include mobile {
    max-height: 100% !important;
    overflow: initial;
  }

  .loader {
    position: absolute;
    left:0;
    right: 0;
    max-height: 100px;
  }
  .comment {

    width: 90%;
    display: flex;
    gap: 20px;
    margin: 20px 0;

    @include mobile {
      width: 100%;
    }

    .comment_user {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .comment_detail {
      max-width: calc(100% - 70px);

      .content {
        padding: 10px 0;
      }
      p {
        font-weight: 300;
      }

      .actions {
        display: flex;
        gap: 20px;

        font-size: 14px;
        color: $darkgray;

        cursor: pointer;
        .action {
          &:hover {
            color: $primary;
          }
        }
      }

      .date {
        font-size: 14px;
        color: $darkgray;
      }
    }
  }
}


.input_container {
  position: fixed;
  bottom: 10px !important;
  left: 0;
  right: 0;
  width: 95%;
  margin: auto;
}