@import "../../../styles/mixins";

.section {
  margin: 30px auto;
  text-align: left;
  position: relative;

  .carousel_button_group {
    position: absolute;
    top: 0;
    right: 0;

    button {
      width: 40px !important;
      height: 10px !important;
      background-color: transparent;
      border: none !important;
      font-size: 24px;
      &:hover {
        background-color: transparent !important;
      }
      &:disabled {
        background-color: transparent !important;
        border: none !important;
        color: rgba(255, 255, 255, 0.238) !important;
        &:hover {
          border: none !important;
          color: rgba(255, 255, 255, 0.238) !important;
        }
      }
    }
  }

  .cards {
    margin: 50px auto;
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 20px);
      height: 100%;
      //background-color: rgba(255, 0, 0, 0.29);
      border-radius: 10px;
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }

    .card {
      flex-basis: calc(33.33% - 20px);
      border-radius: 10px;
      border: solid 2px #22213D;
      background: $secondarybg;

      margin: 0 10px;
      overflow: hidden;

      @include tablet {
        flex-basis: calc(50% - 20px); /* Two items per row with 20px margin */
        margin: 10px auto;
      }

      @include mobile {
        flex-basis: calc(100% - 20px); /* Two items per row with 20px margin */
        margin: 20px auto;
      }

      &:hover {
        border: $border;
      }

      .image {
        width: 100%;
        height: 250px;
        overflow: hidden;
        border-bottom: solid 2px #22213D;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        padding: 15px !important;
        .title {
          padding: 15px 0;
        }
      }
    }
  }
}