@import "../../../../styles/mixins";

.transitionOut {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 800ms ease-in-out;
}

.transitionIn {
  transform: translateX(0);
  opacity: 1;
  transition: all 800ms ease-in-out;
}

.album_view {
  display: block;
}

.gallery {
  margin-top: 20px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }

    .content {
      max-width: 60%;

      @include tablet {
        max-width: 80%;
        margin-bottom: 30px;
      }

      @include mobile {
        max-width: 100%;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      color: #fff;
      margin-bottom: 10px;
    }
    .desc { 
      font-size: 14px;
      line-height: 20px;
      color: rgba(246, 245, 255, 0.6);
    }

    .button {
      width: auto;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-radius: 50px !important;
      padding: 10px 20px;
      border-color: white;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $secondary !important;
        border-color: $secondary !important;
      }
    }

    .back_button {
      width: auto;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-radius: 50px !important;
      padding: 10px 0;
      margin-bottom: 20px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: transparent !important;
        color: $primary !important;
      }
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    gap: 20px;
    margin: 10px auto;

    .card {
      flex: 0;
      flex-basis: 30%;
      margin-bottom: 10px;
      padding: 5px;

      background-color: $secondarybg;
      border-radius: $border-radius;
      border: $border;

      @include tablet {
        flex-basis: calc(50% - 15px);
      }

      @include mobile {
        flex-basis: calc(100% - 15px);
      }

      @include mobile {
        max-width: 100%;
      }

      .image {
        background-size: cover;
        background-position: center;
        overflow: hidden;
        width: 100%;
        min-width: 250px;
        height: 250px;
        border-radius: $border-radius;

        @include tablet {
          height: 300px
        }

        @include mobile {
          height: 250px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @include mobile {
          height: 250px;
        }
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 20px;
        color: #fff;
        padding-top: 10px;
      }

      .description {
        font-size: 14px;
        line-height: 20px;
        color: rgba(246, 245, 255, 0.6);
      }

      &:hover {
        background-color: $border-color;
        border: $border;
        cursor: pointer;
      }
    }
  }
}