@import "../../../styles/mixins";

.mainFeed {
  width: 100%;
  height: max-content;
  overflow: hidden;

  padding-top: 20px;
  padding: 20px;

  @include mobile {
    width: 100%;
    height: max-content;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 70px;
  }
}

/* .feed {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  margin: 0 auto;
  text-align: left;

  padding: 50px 0;
  margin-bottom: 100px;

  a {
    color: #fff !important;
  }

  .feed_item_loading {
    width: $feed-width;
    margin: 40px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0;
    }
  }

  .feed_item {
    width: 100%;
    max-width: $feed-width;
    margin: 60px auto;
    position: relative;

    background: #111023;
    //padding: 10px;
    border-radius: 10px;
    border: solid 1px #22213d;

    @include mobile {
      width: 95%;
      max-width: $feed-width;
      padding: 0;
      border-radius: 0;
      margin: 20px auto;
    }

    .media {
      width: 100%;
      height: 400px;
      min-height: 500px;
      //border-radius: 10px;
      overflow: hidden;
      position: relative;

      @include mobile {
        width: 100%;
        height: 100%;
        min-height: 200px;
        border-radius: 0px;
        border-left: none !important;
        border-right: none !important;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .heart {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 80px;
        height: 80px;
        margin: auto;
        background-image: url('../../../assets/icons/liked.svg');
        background-size: cover;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px;

      img {
        width: 20px;
      }
    }

    .post_content {
      color: #fff;
      padding: 10px 20px;
    }

    .content {
      color: #fff;
      display: flex;
      gap: 12px;
      padding: 20px;
      
      @include mobile {
        padding: 20px;
      }

      .creator {
        width: 50px;
        height: 50px;
        a {
          width: 50px;
          height: 50px;
          display: block !important;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      span {
        font-weight: 400;
        color: rgba(246, 245, 255, 0.613);
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
} */