@import "../../../styles/mixins";

.modalOpen {
  overflow: hidden;
}

.profile_feed {
  .feed_item {
    //max-width: 100% !important;
    max-width: $feed-width;
  }
}

.feed {
  width: 100%;
  //height: 100%;
  //overflow: auto;

  //margin: auto;
  text-align: left;

  // padding-bottom: 100px;
  // padding-top: 50px;

  a {
    color: #fff !important;
  }

  .feed_item_loading {
    width: $feed-width;
    margin: 40px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0;
    }
  }

  .feed_item {
    width: 100%;
    max-width: $feed-width;
    margin: 20px auto;
    position: relative;

    background: $secondarybg;
    //padding: 10px;
    border-radius: 10px;
    border: $border;
    border-color: $border-color;

    /*  &::after {
      content: '';
      position: absolute;
      bottom: auto;
      left: 0;
      height: 1px;
      width: 100%;
      margin: 25px 0;
      background-color: #22213d;
    } */

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0;
      border-radius: 0;
      margin: 20px auto;
      border-left: none !important;
      border-right: none !important;
    }

    .media {
      overflow: hidden;
      position: relative;
      margin: auto;
      text-align: center;
      padding-top: 10px;

      @include mobile {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        border-left: none !important;
        border-right: none !important;
      }

      .media_overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        //border-radius: 10px;
        backdrop-filter: blur(50px);
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;

        border-radius: $border-radius;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        position: relative;

        border-radius: $border-radius;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .heart {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 80px;
        height: 80px;
        margin: auto;
        background-image: url('../../../assets/icons/liked.svg');
        background-size: cover;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }

    /*  &::after {
      content: '';
      margin: auto;
      position: absolute;
      bottom: -18px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $border-color;
    } */

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px;

      .action {
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      img {
        width: 20px;
      }

      span {
        background: rgba(255, 255, 255, 0.20);
        color: rgba(198, 197, 211, 1);
        padding: 0px 8px;
        border-radius: 50px;
      }

      .liked {
        span {
          background: $secondary;
          color: #18191D;
        }
      }

      @include mobile {
        p {
          display: none;
        }
      }
    }

    .post_content {
      overflow: auto;
      color: #fff;
      padding: 10px 20px;

      //margin: 0 10px;
      a {
        color: $secondary !important;
      }
    }

    .content {
      color: #fff;
      display: flex;
      gap: 12px;
      padding: 20px;

      @include mobile {
        padding: 20px;
      }

      .creator {
        width: 50px;
        height: 50px;

        a {
          width: 50px;
          height: 50px;
          display: block !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      .visibility {
        position: absolute;
        top: 25px;
        right: 0;

        background-color: $secondary;
        padding: 0 10px;
        border-radius: $border-radius 0 0 $border-radius;

        font-weight: 600;
      }

      span {
        font-weight: 400;
        color: rgba(246, 245, 255, 0.613);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .comments {
    display: flex;
    padding: 22px 0px;
    margin: 0 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    color: $secondary;
    font-weight: 600;

    border-top: 1px solid $border-color;
    cursor: pointer;
  }



  .noAccess {
    height: auto;

   /*  display: flex;
    justify-content: center;
    align-items: center; */

    .content {
      background-image: url("https://loop-markets.directus.app/assets/ed22f474-a0fe-4381-ba87-f99ab70f08da"), linear-gradient(180deg, #a08dea 0%, #121212 100%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      position: relative;

      display: grid;
      text-align: center;

      img {
        margin: auto;
        width: 124px;
      }

      .AccessButton {
        margin: auto;
        margin-top: 20px;

        background-color: $mainbg;
        border: $border;
        color: #fff;

        &:hover {
          background-color: $secondary !important;
          border: $border;
          color: #fff;
        }
      }
    }

    .NoAccessModal {
      width: 500px;
      margin: auto;
      text-align: center;
      /* background-color: $mainbg;
      padding: 20px 20px;
      border-radius: $border-radius;
      border: solid 1px $border-color; */

      @include mobile {
        width: 80%;
      }

      .AccessButton {
        margin: auto;
        margin-top: 20px;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}