@import "../../../../styles/mixins";

.course {
  width: 100%;
}

.section {
  //margin: 100px auto;
  text-align: left;

  max-width: 800px;
  margin-left: 0;

  p {
    margin: 20px 5px !important;
  }

  .cards {
    margin: 50px auto;

    .card {
      flex-basis: calc(33.33% - 20px);
      border-radius: 10px;
      border: solid 1px #22213D;
      background: $secondarybg;

      margin: 0 10px;
      overflow: hidden;

      @include tablet {
        flex-basis: calc(50% - 20px); /* Two items per row with 20px margin */
        margin: 10px auto;
      }

      @include mobile {
        flex-basis: calc(100% - 20px); /* Two items per row with 20px margin */
        margin: 20px auto;
      }

      .image {
        width: 100%;
        height: 250px;
        overflow: hidden;
        border-bottom: solid 2px #22213D;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        padding: 15px !important;
        .title {
          padding: 15px 0;
        }
      }
    }
  }
}