@import "../../../../styles/mixins";

.course {
  width: 100%;
}

.body {
  max-width: 100%;
  margin: auto;
  padding: 28px;
  overflow: hidden;
}

.grabbing {
  cursor: grabbing;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  width: 100%;
  min-height: 500px;
  height: 500px;
  //padding: 28px;

  margin: auto;
  background: rgb(57, 49, 63);
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: solid 5px #22213D;

  overflow: hidden;
  position: relative;
  z-index: 1;

  @include mobile {
    background-position: center;
  }

  .player_wrapper {
    width: 100% !important;
    height: 100% !important;
    z-index: 2;

    &:hover {
      .player_controls {
        opacity: 1;
      }
    }

    .player_controls {
      height: 40px;
      width: calc(70% - 50px);
      background: #9b83f99b;

      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      margin: auto;
      padding: 4px 10px;

      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;

      border-radius: $border-radius;
      z-index: 99999;

      opacity: 0;
      transition: ease-in-out 500ms;

      img {
        display: flex;
        width: 20px;
        height: 20px;
      }

      .seek_bar {
        width: 100%;
        height: 10px;
        padding: 2px;
        background-color: $secondarybg;
        border-radius: $border-radius;
        position: relative;

        .seek_bar_dragged {
          position: absolute;
          top: 0;
          left: 10px;
          width: 30px;
          height: 10px;
          background-color: $primary;
          border-radius: $border-radius;
          box-shadow: 0 0 15px 0 #000;
        }

        .seek_bar_filled {
          width: 33.9278%;
          height: 100%;
          background-color: $primary;
          border-radius: $border-radius;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #181723d6 !important;
    backdrop-filter: blur(5px);
    z-index: 1;

    @include mobile {
      background: #00000092 !important;
    }
  }

  .content {
    z-index: 2;

    img {
      width: 70px;
      transition: ease-in-out 100ms;

      &:hover {
        cursor: pointer;
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }
}

.feed {
  width: 100%;
  height: 100%;

  margin: auto;
  text-align: left;

  padding-bottom: 100px;
  padding-top: 50px;

  .feed_item_loading {
    width: $feed-width;
    margin: 40px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0 20px;
    }
  }
}