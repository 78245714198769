@import "../../../styles/mixins";

.course {
  width: 100%;
}

.body {
  max-width: 100%;
  margin: auto;
  padding: 28px;
  overflow: hidden;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  width: 100%;
  min-height: 450px;
  padding: 28px;

  margin: auto;
  background: $secondarybg;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: solid 5px #22213D;

  overflow: hidden;
  position: relative;
  z-index: 1;

  @include mobile {
    background-position: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.36deg, #00000092 65%, #8877ea6b 30%, #86e2ee70 88.37%) !important;
    z-index: 1;
    @include mobile {
    background: #00000092 !important;
    }
  }

  .content {
    display: flex;
    gap: 40px;
    z-index: 2;

    .avatar {
      width: 140px;
      height: 140px;
      border: solid 1px #21203E;
      border-radius: 100px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        padding: 10px;
      }
    }

    .content {
      flex: 1;
      margin-top: 20px;
      text-align: left;

      .creator {
        h3 {
          font-weight: 700;
          font-size: 44px;
          line-height: 24px;

          color: #F6F5FF;
        }

        h5 {
          margin: 20px 0;
          color: #F6F5FF;
        }

        .desc {
          width: 500px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          color: rgba(246, 245, 255, 0.6);
          padding: 20px 0;
          @include mobile {
            width: 100%;
          }
        }

        .link {
          display: flex;
          gap: 6px;
          align-items: center;

          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          letter-spacing: 0.02em;

          color: $primary;
        }
      }

      .actions {
        display: flex;
        gap: 10px;
        button {
          width: auto !important;
          height: 40px;
          border: 1px solid rgba(105, 89, 230, 0.4) !important;
          border-radius: 19px !important;
          padding: 0 20px !important;
        }
        .solidBtn {
          background: $primary !important;
          color: #fff !important;
          border-radius: 19px !important;
        }
      }
    }
  }
}

.feed {
  width: 100%;
  height: 100%;

  margin: auto;
  text-align: left;

  padding-bottom: 100px;
  padding-top: 50px;

  .feed_item_loading {
    width: $feed-width;
    margin: 40px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0 20px;
    }
  }

  .feed_item {
    width: $feed-width;
    margin: 60px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0 20px;
    }

    /*  &::after {
      content: '';
      margin: auto;
      position: absolute;
      bottom: -18px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $border-color;
    } */

    .image_container {
      width: 100%;
      height: 400px;
      min-height: 500px;
      border-radius: 15px;

      overflow: hidden;
      position: relative;

      @include mobile {
        width: 100%;
        //height: 400px;
        min-height: 400px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .heart {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 80px;
          height: 80px;
          margin: auto;
          background-image: url('../../../assets/icons/liked.svg');
          background-size: cover;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include mobile {
        width: 100%;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px 0;

      img {
        width: 20px;
      }
    }

    .content {
      color: #fff;
      display: flex;
      gap: 12px;

      .creator {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      span {
        font-weight: 400;
        color: rgba(246, 245, 255, 0.613);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}