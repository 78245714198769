@import "../../../styles/mixins";

.modal {
  .navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    width: 100vw;
    z-index: 9999;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    margin: auto;

    @include tablet {
      padding: 0 30px;
    }

    @include mobile {
      top: 50px;
      bottom: auto;
      padding: 0 20px;
      display: none;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      background: $secondarybg;
      border-radius: 44px;
      transition: ease-in-out 400ms;

      &:hover {
        background: $mainbg;
      }

      &:disabled {
        background: #2d2c5455;
        opacity: 0.6;
      }
    }
  }

  .modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.718);
    height: 100%;
    width: 100vw;
    z-index: 999;
  }

  .modal_content {
    display: flex;
    gap: 50px;
    position: fixed;
    margin: 15px auto;
    padding: 40px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    text-align: left;

    width: calc(100% - 400px);
    max-width: 600px;
    height: auto;
    min-height: 500px;

    background-color: $mainbg;
    border-radius: $border-radius /* calc($border-radius + 20px) */;
    border: $border;
    border-color: $border-color;
    overflow: hidden !important;

    @include mobile {
      margin: auto;
      padding: 10px;
      border: none !important;
    }

    .close_button {
      position: absolute;
      top: 5px;
      right: 20px;
      width: 50px !important;
      height: 50px !important;
      font-size: 42px;
      padding: 10px !important;
      border: none !important;
      border-radius: $border-radius + 50 !important;
      transform: rotate(270deg);
      &:hover {
        background: transparent !important;
      }

      &::after {
        content: "x";
        background-image: url("../../../assets/icons/close.svg");
        background-repeat: no-repeat;
        background-position: center;
        color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
      }

      @include mobile {
        width: 50px !important;
        height: 50px !important;
        right: 10px;
        z-index: 99999 !important;
        img {
          display: flex;
        }
      }
    }

    @include tablet {
      width: calc(100% - 100px);
      border-radius: 0;
      overflow: scroll;
    }

    @include mobile {
      display: block;
      width: 100%;
      height: 100%;
    }

    .content_image {
      width: 600px;
      min-height: 500px;
      max-height: 600px;
      overflow: hidden;
      border-radius: 15px;
      flex: 1;

      @include tablet {
        width: 600px;
        max-height: 400px;
      }

      @include mobile {
        display: none;
        width: 100%;
        height: 100% !important;
        min-height: 400px !important;
        max-height: 400px !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      //padding-bottom: 40px;
      //height: calc(100% - 140px);
      overflow: hidden;

      @include mobile {
        padding-bottom: 20px;
        height: calc(100% - 140px);
      }

      .comments {
        height: 100%;
        overflow: scroll;
        /* @include mobile {
          height: calc(100% - ($mobileMenuHight * 2));
          overflow: scroll;
        } */
      }
      
      .actions {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 0 20px;
        span {
          display: flex;
          gap: 10px;
          &:hover {
            cursor: pointer;
          }
        }
        img {
          width: 20px;
        }
      }
      .input {
        width: 100%;
        background: $input-color;
        border-radius: 12px;

        padding: 20px;
        &:focus {
          border: $border-color;
          border-color: $border-color;
          box-shadow: 0px 0px 8px $border-color;
        }
      }

      @include mobile {
        .footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 10px 20px;
          border-top: solid 1px #22213d;
          background: $secondarybg;
        }
      }
    }
  }
}