@import "../../../styles/mixins";

.button {
  width: 212px;
  padding: 15px 24px;
  background: $primary;
  border: solid 1px $primary;
  border-radius: 19px;

  font-weight: 500;
  color: #fff;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
}

.button_outline {
  background: transparent;
  color: #887DEA;
  border: solid 2px #887DEA;
  border-radius: 12px;

  &:hover {
    background: #8168e1;
    color: #fff !important;
  }
}

.btn_sm {
  width: fit-content !important;
  //width: 150px !important;
}