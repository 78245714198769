@import "./styles/mixins";

html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: auto;
  margin-top: 80px;
  //overflow: hidden !important;
  -webkit-overflow-scrolling: touch;

  @include mobile {
    overflow: initial !important;
  }
}

#root {
  width: 100%;
  //overflow: hidden !important;

  @include mobile {
    overflow: initial !important;
  }
}

.container {
  max-width: 1600px;
  height: 100%;
  margin: auto;
}


.App {
  //text-align: center;
}

body,
#root,
.App {
  width: 100%;
  //height: 100%;
  min-height: 100%;
  position: relative;
}

.content {
  width: 100%;
  height: 100%;

  margin: auto;
  padding: 10px 20px;
  overflow: hidden;

  @include mobile {
    padding: 10px;
  }
}

.overflowHidden {
  overflow: hidden !important;
}


h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  span {
    background: linear-gradient(123.75deg, #9B83F9 53.46%, #8164F5 66.94%, #27AEF8 80.94%, #31A2FA 97.26%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

//==========================//
// Buttons
//==========================//
.button {
  margin: 40px 0;
  gap: 10px;
  align-items: center;
  width: auto !important;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 20px;
  border-radius: 50px !important;
  padding: 10px 20px !important;

  background-color: $secondary !important;
  border-color: $primary !important;
  color: #fff !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $primary !important;
  }
}
.button_locked {
  display: flex;
  gap: 10px;
  align-items: center;
  width: auto !important;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  border-color: $primary !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $primary !important;
  }
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;

  img {
    display: none;

    @include tablet {
      display: block;
    }
  }
}


.App-logo {
  margin-top: 100px;
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: $primary;

  &:hover {
    color: #fff !important;
  }
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;

  color: #F6F5FF;
}

h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

  color: #F6F5FF;
}

.errorCard {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .errorMessage {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 400px;
    height: 400px;
    border-radius: 18px;
    background-color: $secondarybg;
    border: solid 1px $border-color;

    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 900;
      color: #fff;
    }

    span {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #a1a1a1;
    }

    .errorMessage_div {
      width: 100%;
      height: 3px;
      background-color: $border-color;

      margin: 10px 0;
    }
  }
}

.breadcrumb {
  display: block;
  padding-bottom: 20px !important;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: white;
  /*need more margin on the left of links to accomodate the numbers*/
  padding: 0 10px 0 60px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}

/*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
.breadcrumb a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px;
  /*to match with the parent's radius*/
}

.breadcrumb a:first-child:before {
  left: 14px;
}

.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  /*this was to prevent glitches on hover*/
  padding-right: 20px;
}

/*hover/active styles*/
.breadcrumb a.active {
  background: #333;
  background: linear-gradient(#333, #000);
}

.breadcrumb a.active:after {
  background: #333;
  background: linear-gradient(135deg, #333, #000);
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.breadcrumb a:after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  /*half of square's length*/
  /*same dimension as the line-height of .breadcrumb a */
  width: 36px;
  height: 36px;
  /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
  transform: scale(0.707) rotate(45deg);
  /*we need to prevent the arrows from getting buried under the next link*/
  z-index: 1;
  /*background same as links but the gradient will be rotated to compensate with the transform applied*/
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  /*stylish arrow design using box shadow*/
  box-shadow:
    2px -2px 0 2px $secondarybg,
    3px -3px 0 2px rgba(255, 255, 255, 0.1);
  /*
		5px - for rounded arrows and 
		50px - to prevent hover glitches on the border created using shadows*/
  border-radius: 0 5px 0 50px;
}

/*we dont need an arrow after the last link*/
.breadcrumb a:last-child:after {
  content: none;
}

/*we will use the :before element to show numbers*/
.breadcrumb a:before {
  content: counter(flag);
  counter-increment: flag;
  /*some styles now*/
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  background: linear-gradient(#444, #222);
  font-weight: bold;
}


.flat a,
.flat a:after {
  background: white;
  color: black;
  transition: all 0.5s;

  &:hover {
    cursor: default !important;
  }
}

.flat a:before {
  background: white;
  box-shadow: 0 0 0 1px #ccc;
}

.flat a.active,
.flat a.active:after {
  background: $primary;

  &:hover {
    background: $primary;
    color: #000 !important;
    cursor: pointer;
  }
}

.flat .active {
  &:hover {
    background: $primary;
    color: #000 !important;
    cursor: pointer;
  }
}

.flat a:hover {
  color: #000 !important;
  cursor: default;
}

.loader,
.loader:before,
.loader:after {
  background: #4c4969;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #4c4969;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


.card {
  max-width: 600px;
  margin: 50px auto;
  padding: 50px;
  background: $secondarybg;
  border: $border;
  border-color: $border-color !important;
  border-radius: $border-radius;

  img {
    max-width: 200px;
    margin: 20px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    letter-spacing: -0.03em;

    color: #FFFFFF;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #FFFFFF;

    opacity: 0.7;

    padding: 10px 0 30px;
  }
}

.btn_full {
  width: 100% !important;
}

.btn_sm {
  width: fit-content;
  padding: 10px 20px !important;
  //width: 150px !important;
}

.btn_solid {
  color: #fff !important;
  background: $primary !important;
  margin: 20px auto !important;

}

.btn_centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.btn_default {
  background: $border-color !important;
}


.language-selector {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;

  button {
    //display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background: transparent !important;
    border: none !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    padding: 5px !important;
    margin: 0 5px !important;

    img {
      display: block !important;
      width: 24px;
    }
  }

  .dropdown-options {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;

    padding: 10px;
    border-radius: 5px;
    width: max-content;
    background-color: #fff;

    div {
      display: flex;
      align-items: center;
      //justify-content: center;
      gap: 5px;

      padding: 5px 0;
      cursor: pointer;

      img {
        width: 24px;
      }
    }
  }
}

.textarea_container {
  position: relative;
  width: 100%;
  height: 100%;

  .send_icon {
    position: absolute;
    top: 30%;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    z-index: 99999;
  }

}

.hideMobile {
  @include mobile {
    display: none;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.moreText {
  display: inline-block;

  p {
    display: contents;
  }
}

.moreTextOpen {
  color: $secondary;
  cursor: pointer;
}