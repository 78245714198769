@import "../../../../styles/mixins";

.mainFeed {
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding-top: 20px;

  @include mobile {
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 70px;
  }
}

.feed {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  //gap: 5px;
  margin: 3px auto;

  .card {
    //flex-grow: 1;
    flex-basis: calc(33.333333% - 4px);
    //border: solid 1px #211F3A;
    border: solid 1px $border-color;
    margin: 2px;
    overflow: hidden;

    @include mobile {
      flex-basis: calc(50% - 4px);
    }

    .courseImage {
      overflow: hidden;
      width: 100%;
      height: 250px;

      @include mobile {
        height: 200px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      padding: 10px 0;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      color: rgba(246, 245, 255, 0.6);
    }

    &:hover {
      background-color: $hoverbg;
      border: $border;
      cursor: pointer;
    }
  }
}

.feedContainer {
  height: calc(100vh - 325px);
  overflow: auto;
  @include mobile {
    height: calc(100vh - 445px);
  }
}