@import "../../../../styles/mixins";

.event {
  margin: 20px 0;
  .event_detail_view {
    padding: 10px;

    .image {
      background-size: cover;
      background-position: center;
      overflow: hidden;
      width: 100%;
      height: 300px;
      border-radius: $border-radius;
      margin-bottom: 50px;

      @include tablet {
        height: 300px
      }

      @include mobile {
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .section {
      max-width: 80%;
      margin-bottom: 35px;

      @include tablet {
        max-width: 80%;
        margin-bottom: 30px;
      }

      @include mobile {
        max-width: 100%;
      }

    }

    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      color: $white;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 18px;
      line-height: 25px;
      color: rgba(246, 245, 255, 0.6);
    }

    .detail {
      display: flex;
      flex-direction: row-reverse;

      @include tablet {
        flex-direction: column-reverse;
      }

      @include mobile {
        flex-direction: column-reverse;
      }

      .left {
        width: 100%;
      }

      .right {
        width: 40%;

        @include tablet {
          width: 100%;
        }
        @include mobile {
          width: 100%;
        }
        .collection {
          font-size: 16px;
          text-align: center;
          padding-top: 20px;
          color: rgba(246, 245, 255, 0.6);

          .live {
            color: $secondary;
          }
          .ended {
            color: $red;
          }
        }
      }
    }

    .button {
      width: 100%;
      font-size: 18px;
      font-weight: bolder;
      line-height: 20px;
      color: #fff;
      background-color: $secondary;
      border-radius: 150px !important;
      padding: 10px 20px;
      border-color: $secondary;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $secondary !important;
        border-color: $secondary !important;
      }

      &:disabled {
        background-color: $gray !important;
        border-color: $gray !important;
        color: #000;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .back_button {
      width: auto;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-radius: 50px !important;
      padding: 10px 0;
      margin-bottom: 20px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: transparent !important;
        color: $primary !important;
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  
    .card {
      flex-basis: 100%;
      border-radius: $border-radius;
      border: $border;
      margin-bottom: 20px;
      cursor: pointer;
  
      &:hover {
        background-color: $border-color;
        border: $border;
        border-color: #ffffff2f;

        img {
          border-radius: $border-radius $border-radius 0 0;
        }
      }
  
      @include desktop {
        flex-basis: calc(50% - 20px);
      }
  
      @include tablet {
        flex-basis: calc(50% - 20px);
      }
  
      @include mobile {
        flex-basis: calc(100%);
      }
  
      img {
        width: 100%;
        min-width: 300px;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        border-radius: $border-radius;
  
        @include tablet {
          height: 300px
        }
  
        @include mobile {
          height: 250px;
        }
  
        @include mobile {
          height: 250px;
        }
      }
  
      .content {
        padding: 10px;
  
        .title {
          font-size: 26px;
          font-weight: bold;
          line-height: 30px;
          color: #fff;
          margin-bottom: 10px;
        }
  
        .desc {
          font-size: 18px;
          line-height: 25px;
          color: rgba(246, 245, 255, 0.6);
        }
  
        .price {
          font-size: 18px;
          line-height: 40px;
          color: $white;
          padding-top: 5px;
          font-weight: bolder;
        }
  
      }
    }
  }
}