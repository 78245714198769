@import "../../../styles/mixins";

.feed_header {
  width: $feed-width;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    width: 100%;
    max-width: $feed-width;
    padding: 0 20px;
  }

  @include mobile {
    width: 100%;
    max-width: $feed-width;
    padding: 0 20px;
  }

  .left {
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 17px;

      color: rgba(246, 245, 255, 0.6);
    }
  }

  .button_outline {
    text-align: center;
    width: 120px !important;
    border: 1.5px solid rgba(136, 119, 234, 0.5);
    border-radius: 19px;

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    color: #8877EA;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  .loading {
    animation: rotate 2s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}