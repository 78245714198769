@import "../../../styles/mixins";

.wallet_login_wrapper {
  margin: auto;

  .divider {
    margin: 10px 0 15px 0;
    span {
      font-size: 18px;
      font-weight: 600 ;
    }
  }

  .signup {
    width: 280px;
    height: 57px;
    border-color: #fff !important;
    color: #fff !important;

    &:hover {
      border-color: transparent !important;
    }

    @include mobile {
      width: auto;
      min-width: 100px !important;
    }
  }
  
  .loginbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}