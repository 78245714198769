@import "../../../styles/mixins";

.input {
  width: 100%;
  height: 70px;
  background: $input-color;
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 20px;

  &:focus {
    border: 1px solid $border-color;
    box-shadow: 0px 0px 8px $border-color;
  }
}